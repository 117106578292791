import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // 导入 Routes 组件
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav0';
import Footer from './Home/Footer1';
import Home from './Home';
import AboutUs from './aboutus'; // 导入 AboutUs 组件

import {
  Nav00DataSource,
  Footer10DataSource,
} from './Home/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  
  componentDidMount() {
    // 适配手机屏幕
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  
  render() {
    return (
      <Router>
        <div>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Routes> {/* 使用 Routes 包裹所有 Route */}
            <Route exact path="/" element={<Home />} /> {/* 使用 JSX 语法 */}
            <Route exact path="/aboutus" element={<AboutUs />} /> {/* 使用 JSX 语法 */}
          </Routes>
          <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile} />
        </div>
      </Router>
    );
  }
}

export default App;
