import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://bin.misinguo.com/2024/08/upgit_20240830_1724993914.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item~m0g9ae1toos',
        className: 'header0-item',
        children: {
          href: './',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>首页</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>产品招募</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>产品需求</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>加入我们</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/aboutus',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>关于我们</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper m0m88yifq7j-editor_css',
    playScale: [0.7, 0.7],
    replay: false,
    always: false,
    appear: false,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title~m0m8cqxbw4h',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <p>郑州民深数据科技有限公司</p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'title~m0m8xhlk8xs',
        className: '',
        children: (
          <span>
            <p>是集数据产品研发、运营、推广、销售为一体的一家数据科技公司。</p>
          </span>
        ),
      },
      {
        name: 'title~m0m8cr1jjbs',
        className: '',
        children: (
          <span>
            <span>
              <p>我们采用大模型及搜索系统生成知识库从而为客户产品赋能</p>
            </span>
          </span>
        ),
      },
      {
        name: 'title~m0m8xis6jll',
        className: '',
        children: (
          <span>
            <span>
              <p>通过数字营销系统为客户完成产品定向推广。</p>
            </span>
          </span>
        ),
      },
      {
        name: 'title~m0m8cr5jms',
        className: '',
        children: (
          <span>
            <p>企业使命：有价值的数据深入人心</p>
          </span>
        ),
      },
      {
        name: 'title~m0m8cr9k0q9',
        className: '',
        children: (
          <span>
            <p>愿景：有价值的信息终将在你我之间流动</p>
          </span>
        ),
      },
      {
        name: 'title~m0m8crcnypt',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <p>价值观：利他是更好的利己。</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block~m0kefly7wg',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children:
            'https://bin.misinguo.com/2024/08/upgit_20240830_1724993914.png',
        },
        childWrapper: {
          children: [
            {
              name: 'content~m0m7ovope5q',
              className: 'm0m7p7z4aur-editor_css',
              children: (
                <span>
                  
                  <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">豫ICP备2023040351号-2</a>
                  
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <p>产品招募</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>产品需求</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~m0gm8pfgik',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p>关于我们</p>
                  </span>
                </span>
              ),
            },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block~m0gmegy4w8',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      <a href="http://">加入我们(本网站为测试网站)</a>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright m0ke4gq51ir-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              ©2024 by&nbsp;<a href="https://www.misindata.com">
                郑州民深数据科技有限公司
              </a>&nbsp;All Rights Reserved<br />
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
